/* eslint-disable max-len */
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, FormFeedback, Input, Label, Row } from 'reactstrap';

import { yupResolver } from '@hookform/resolvers/yup';
import { validateCPF } from 'validations-br';

import CustomMaskedInput from '../../components/CustomMaskedInput';
import CustomSelect from '../../components/CustomSelect';
import DatePickerMaskedInput from '../../components/DatePickerMaskedInput';
import MoneyMaskedInput from '../../components/MoneyMaskedInput';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';
import { schema } from './validationSchema';

const defaultValues = {
  contaReceberDe: 'cliente',
};

function ContaReceber() {
  const navigate = useNavigate();
  const { loading, setLoading, notificationRef } = useContext(AppContext);
  const [clientes, setClientes] = useState([]);
  const { control, watch, setError, handleSubmit, formState: { errors } } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const watchContaReceberDe = watch('contaReceberDe');

  useEffect(() => {
    (async () => {
      try {
        const { data = [] } = await api.get('/users?limit=0');
        const clientesSelect = [];
        data.forEach(({ id, responsaveis = [], criancas = [] }) => {
          const options = [];
          responsaveis.forEach((r) => {
            options.push({
              value: id,
              cpf: r.cpf,
              nome: r.nome,
              label: `🙍 ${r.nome} (Responsável)`,
            });
          });

          criancas.forEach((c) => {
            options.push({
              value: id,
              cpf: c.cpf,
              nome: c.nome,
              label: ` ➡️ 👨‍🍼 ${c.nome} (Filho)`,
            });
          });

          clientesSelect.push({
            label: '',
            options,
          });
        });
        console.log(clientesSelect);

        setClientes(clientesSelect);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const onSubmit = useCallback((formValues) => {
    const { contaReceberDe, cliente, nome, cpf, valor, dataVencimento } = formValues;
    // console.log(formValues);

    (async () => {
      try {
        if (contaReceberDe === 'cliente' && !cliente?.value) {
          setError('fornecedor', { type: 'manual', message: 'Selecione um Cliente' });
          return;
        }

        if (contaReceberDe === 'cliente-avulso') {
          if (!nome) {
            setError('nome', { type: 'manual', message: 'Informe o nome do Cliente' });
            return;
          }

          if (!cpf) {
            setError('cpf', { type: 'manual', message: 'Informe o CPF do Cliente' });
            return;
          }

          if (!validateCPF(cpf)) {
            setError('cpf', { type: 'manual', message: 'CPF inválido' });
            return;
          }
        }

        setLoading(true);
        const data = {
          tipo: 'conta-receber',
          valor,
          dataVencimento,
        };

        if (contaReceberDe === 'cliente') {
          Object.assign(data, {
            cliente: {
              id: cliente?.value,
              nome: cliente?.nome,
              cpf: cliente?.cpf,
            },
          });
        } else {
          Object.assign(data, {
            cliente: {
              nome,
              cpf,
            },
          });
        }

        console.log(data);
        await api.post('/financeiros', data);
        setLoading(false);
        notificationRef.current.notify({
          message: 'Conta a receber criado com sucesso',
        });

        setTimeout(() => {
          navigate('/financeiro');
        }, 1000);
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao cadastrar fatura',
          color: 'danger',
        });
      }
    })();
  }, [setError]);

  return (
    <Container fluid>
      <Row className="mt-5">
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Cadastrar Conta a Receber</h3>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row className="mb-4">
                  <Col>
                    <Controller
                      name="contaReceberDe"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormGroup check>
                          <Input
                            type="radio"
                            value="cliente"
                            checked={value === 'cliente'}
                            onChange={onChange}
                          />
                          <Label check>Cliente</Label>
                        </FormGroup>
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col>
                    <Controller
                      name="contaReceberDe"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormGroup check>
                          <Input
                            type="radio"
                            value="cliente-avulso"
                            checked={value === 'cliente-avulso'}
                            onChange={onChange}
                          />
                          <Label check>Cliente Avulso</Label>
                        </FormGroup>
                      )}
                    />
                  </Col>
                </Row>
                {watchContaReceberDe === 'cliente' ? (
                  <Row>
                    <Col>
                      <Controller
                        name="cliente"
                        control={control}
                        render={({ field }) => (
                          <CustomSelect
                            label="Cliente"
                            placeholder="Selecione um Cliente"
                            invalidMessage={errors?.cliente?.message}
                            {...field}
                            options={clientes}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col>
                      <Controller
                        name="nome"
                        control={control}
                        render={({ field: { onChange, name, value } }) => (
                          <FormGroup>
                            <Label for="nome">Nome do Cliente</Label>
                            <Input
                              id="nome"
                              type="text"
                              placeholder="Nome do Cliente"
                              name={name}
                              value={value}
                              onChange={onChange}
                              invalid={!!errors?.nome?.message}
                            />
                            <FormFeedback>{errors?.nome?.message}</FormFeedback>
                          </FormGroup>
                        )}
                      />
                      <Controller
                        name="cpf"
                        control={control}
                        render={({ field: { onChange, name, value } }) => (
                          <CustomMaskedInput
                            id="cpf"
                            label="CPF"
                            placeholder="Informe um CPF"
                            format="###.###.###-##"
                            name={name}
                            value={value}
                            onValueChange={({ value: v }) => onChange(v)}
                            invalidMessage={errors?.cpf?.message}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Controller
                      name="valor"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <MoneyMaskedInput
                          label="Valor"
                          name={name}
                          invalidMessage={errors?.valor?.message}
                          value={value}
                          onValueChange={({ floatValue }) => onChange(floatValue)}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Controller
                      name="dataVencimento"
                      control={control}
                      render={({ field }) => (
                        <DatePickerMaskedInput
                          label="Data do Vencimento"
                          invalidMessage={errors?.dataVencimento?.message}
                          onChange={(e) => field.onChange(e)}
                          selected={field.value}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12} sm={6}>
            <Button type="submit" color="success" disabled={loading} block>Cadastrar</Button>
          </Col>
          <Col xs={12} sm={6}>
            <Button color="danger" disabled={loading} onClick={() => navigate(-1)} block>Cancelar</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default ContaReceber;
