/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useCallback, useMemo, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';

import TableManager from '../../components/TableManager';
import UploadFiles from '../../components/UploadFiles';
import { AppContext } from '../../contexts/app';
import { useManager } from '../../hooks/manager';
import api from '../../services/api';

function Clientes() {
  const navigate = useNavigate();
  const { setLoading, notificationRef } = useContext(AppContext);
  const { load, update, destroy } = useManager();
  const [id, setId] = useState();
  const [showDocumentosAnexos, setShowDocumentosAnexos] = useState(false);
  const [documentosAnexados, setDocumentosAnexos] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [email, setEmail] = useState('');
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  useEffect(() => {
    load('/users', 1, []);
  }, [load]);

  const handleShowDocumentosAnexos = useCallback((row) => {
    setId(row._id);
    setDocumentosAnexos(row.documentosAnexados || []);
    setShowDocumentosAnexos(true);
  }, []);

  const userApprove = useCallback(({ _id }) => {
    setLoading(true);
    update(_id, '/userApproves', {}, () => setLoading(false), () => setLoading(false));
  }, []);

  const userRepprove = useCallback(({ _id }) => {
    setLoading(true);
    update(_id, '/userRepproves', {}, () => setLoading(false), () => setLoading(false));
  }, []);

  const handleCreatePdf = useCallback((selectedRows = []) => {
    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Aguarde... Estamos gerando o arquivo PDF... ',
        });

        // await fetch api
        const res = await api.get(`/userPDFCreatorReportLists?clientesIds=${JSON.stringify(selectedRows.map(({ criancas: { _id } }) => _id))}`, { responseType: 'blob' });
        const fileName = res.headers['content-disposition']
          .replace('attachment; filename=', '')
          .replace('"', '');

        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.download = fileName;
        alink.click();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar ao gerar relatório',
          color: 'danger',
        });
      }
    })();
  }, [setLoading]);

  const handleCreateCsv = useCallback((selectedRows) => {
    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Aguarde... Estamos gerando o arquivo CSV... ',
        });

        // await fetch api
        const res = await api.get(`/userCSVCreatorReportLists?clientesIds=${JSON.stringify(selectedRows.map(({ criancas: { _id } }) => _id))}`, { responseType: 'blob' });
        const fileName = res.headers['content-disposition']
          .replace('attachment; filename=', '')
          .replace('"', '');

        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.download = fileName;
        alink.click();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar ao gerar relatório',
          color: 'danger',
        });
      }
    })();
  }, [setLoading]);

  const handleUpdatePassword = useCallback((data) => {
    if (!data?.responsaveis?.map?.((r) => r.email)?.join?.(', ')) {
      notificationRef.current.notify({
        message: 'Cliente sem responsável cadastrado',
        color: 'warning',
      });
      return;
    }
    setValidationErrors({});
    setId(data._id);
    setEmail(data?.responsaveis?.map?.((r) => r.email)?.join?.(', '));
    setPassword('');
    setPasswordConfirmation('');
    setShowModalPassword(true);
  }, []);

  const handleSavePassword = async () => {
    try {
      const data = {
        password,
        passwordConfirmation,
      };

      const schema = Yup.object().shape({
        password: Yup.string().required('Informe a nova senha'),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'As senhas precisam ser iguais',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.put(`/users/${id}/passwords`, data);
      const { message } = response.data;

      notificationRef.current.notify({
        message,
      });

      setShowModalPassword(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setValidationErrors({});
      // Validation failed
      if (err instanceof Yup.ValidationError) {
        notificationRef.current.notify({
          message:
            'Por favor, preencha todos os dados necessários para salvar',
          color: 'warning',
        });
        const ve = {};
        err.inner.forEach((e) => {
          ve[e.path] = e.message;
        });
        setValidationErrors(ve);
        return;
      }

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    }
  };

  const imageFormatter = useCallback((row) => (
    row?.responsaveis?.[0]?.image ? (
      <img src={encodeURI(`${process.env.REACT_APP_STORAGE_IMAGE_URL}/${row?.responsaveis[0]?.image}`)} alt={encodeURI(`${process.env.REACT_APP_STORAGE_IMAGE_URL}/${row?.responsaveis[0]?.image}`)} width="30px" />
    ) : (
      <i className="fa-regular fa-image" style={{ fontSize: '2rem' }} />
    )
  ), []);

  const grupoFormatter = useCallback((row) => {
    // console.log(row);
    if (row?.grupo?.nome) {
      return (
        <div
          className={classNames('w-100 badge', { 'text-white': !!row?.grupo?.color || row?.grupo?.color !== '#ffffff' })}
          style={{ backgroundColor: row?.grupo?.color ? `${row.grupo.color}` : 'var(--white)' }}
        >
          <i className="fa-solid fa-circle mr-2" />
          {row.grupo.nome}
        </div>
      );
    }

    return (
      <Badge className="w-100" tag="div">
        <i className="fa-solid fa-exclamation-circle mr-2" />
        Sem grupo
      </Badge>
    );
  }, []);

  const statusFormatter = useCallback((row) => (
    row.approved ? (
      <>
        <UncontrolledTooltip target={`approve-${row._id}`}>
          Aprovado. Clique para Reprovar.
        </UncontrolledTooltip>
        <Button
          id={`approve-${row._id}`}
          color="link"
          onClick={() => userRepprove(row)}
        >
          <i
            className="fas fa-thumbs-up text-success mt-2"
          />
        </Button>
      </>
    ) : (
      <>
        <UncontrolledTooltip target={`approve-${row._id}`}>
          Reprovado. Clique para Aprovar.
        </UncontrolledTooltip>
        <Button
          id={`approve-${row._id}`}
          color="link"
          onClick={() => userApprove(row)}
        >
          <i
            className="fas fa-thumbs-down text-danger mt-2"
          />
        </Button>
      </>
    )
  ), []);

  const solicitationDeleteAccountFormatter = useCallback((row) => {
    if (row?.solicitationDeleteAccount) {
      return (
        <>
          <UncontrolledTooltip target={`solicitationDeleteAccount-${row._id}`}>
            {`Solicitou exclusão de conta - ${row?.solicitationDeleteAccount?.description ? `Motivo - ${row.solicitationDeleteAccount.description}` : 'Motivo não informado'}`}
          </UncontrolledTooltip>
          <i id={`solicitationDeleteAccount-${row._id}`} className="fa-solid fa-hand text-danger" />
        </>
      );
    }

    return null;
  }, []);

  const statusFilter = useMemo(
    () => [
      {
        value: true,
        label: 'Ativo',
      },
      {
        value: false,
        label: 'Inativo',
      },
    ],
    [],
  );

  const solicitationDeleteAccountFilter = useMemo(
    () => [
      {
        value: true,
        label: 'Solicitou',
      },
      {
        value: false,
        label: 'Não Solicitou',
      },
    ],
    [],
  );

  const columns = useMemo(
    () => [
      {
        dataField: 'image_url',
        text: 'Imagem',
        formatter: imageFormatter,
        width: '50px',
      },
      {
        id: 'grupo',
        dataField: 'grupo.nome',
        text: 'Grupo',
        formatter: grupoFormatter,
        textFilter: true,
      },
      {
        id: 'tabelaValores',
        dataField: 'tabelaValores.nome',
        text: 'Tabela de Valor',
        textFilter: true,
        isArray: true,
      },
      {
        id: 'nome',
        dataField: 'criancas.nome',
        text: 'Paciente',
        placeholder: 'Buscar por Criança ou Responsável',
        textFilter: true,
      },
      {
        id: 'email',
        dataField: 'responsaveis.email',
        text: 'Email do Responsavel',
        textFilter: true,
        isArray: true,
        width: '100%',
      },
      {
        id: 'cpf',
        dataField: 'criancas.cpf',
        text: 'CPF',
        placeholder: 'Buscar por CPF da Criança ou Responsável',
        textFilter: true,
        isArray: true,
      },
      {
        dataField: 'createdAt',
        text: 'Data de Cadastro',
        dateFilter: true,
        formatter: (row) => (row?.createdAt ? format(parseISO(row.createdAt), 'dd/MM/yyyy') : ''),
        width: '80px',
      },
      {
        dataField: 'approved',
        text: 'Status',
        formatter: statusFormatter,
        selectFilter: statusFilter,
        className: 'text-center',
        width: '150px',
      },
      {
        dataField: 'solicitationDeleteAccount',
        text: 'Excluir Conta?',
        formatter: solicitationDeleteAccountFormatter,
        selectFilter: solicitationDeleteAccountFilter,
        className: 'text-center',
        width: '150px',
      },
    ],
    [],
  );

  const actions = useMemo(
    () => [
      {
        name: 'password',
        tooltip: 'Alterar Senha',
        color: 'warning',
        className: 'mt-2 mr-2 text-white',
        icon: 'fas fa-key',
        onClick: (row) => handleUpdatePassword(row),
      },
      {
        name: 'documentos-anexados',
        tooltip: 'Documentos Anexados',
        color: 'info',
        className: 'mt-2 mr-2',
        icon: () => (<i className="fa-solid fa-paperclip" />),
        onClick: handleShowDocumentosAnexos,
      },
    ],
    [],
  );

  return (
    <Container fluid>
      <Row className="mt-5">
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Clientes</h3>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <Row>
                    <Col xs={12} sm={5} md={3} className="mb-3 mb-xl-0">
                      <Button block color="success" onClick={() => navigate('/cliente')}>
                        Cadastrar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableManager
            initialColumns={columns}
            actions={actions}
            onUpdate={(row) => navigate(`/cliente/${row._id}`)}
            onDelete={(row) => destroy(row._id, '/users')}
            onCreatePdf={handleCreatePdf}
            onCreateCsv={handleCreateCsv}
            selectable
            selectableColumns
            enableDeletedItemsButton
          />
        </Col>
      </Row>
      <Modal
        isOpen={showDocumentosAnexos}
        size="lg"
        toggle={() => setShowDocumentosAnexos((prevState) => !prevState)}
        centered
      >
        <ModalHeader>
          <strong>Documento(s) Anexado(s)</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowDocumentosAnexos(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <UploadFiles
            accept={{
              'image/jpeg': [],
              'image/png': [],
              'application/pdf': [],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
              'application/vnd.ms-excel.sheet.binary.macroEnabled.12': [],
              'application/vnd.ms-excel': [],
              'application/vnd.ms-excel.sheet.macroEnabled.12': [],
              'application/msword': [],
              'application/vnd.ms-word.document.macroEnabled.12': [],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.template': [],
              'text/csv': [],
            }}
            height="150px"
            url="/clienteDocumentoAnexos"
            parentId={id}
            initialFiles={documentosAnexados}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setShowDocumentosAnexos(false)}>Fechar</Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showModalPassword}
        toggle={() => setShowModalPassword(!showModalPassword)}
        centered
      >
        <ModalHeader>
          <strong>{`Alterar Senha dos responsáveis do cliente com o(s) email(s) ${email}`}</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowModalPassword(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <InputGroup
                className={`input-group-merge ${validationErrors.password ? 'invalid' : ''
                }`}
              >
                <Input
                  type={passwordType}
                  placeholder="Nova Senha"
                  value={password}
                  invalid={!!validationErrors.password}
                  onChange={({ target: { value } }) => setPassword(value)}
                />
                <InputGroupText onClick={() => setPasswordType((prevState) => (prevState === 'password' ? 'text' : 'password'))}>
                  <i
                    className={`fas ${passwordType === 'password'
                      ? 'fa-eye-slash'
                      : 'fa-eye'
                    }`}
                  />
                </InputGroupText>
                <FormFeedback>{validationErrors.password}</FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup
                className={`input-group-merge ${validationErrors.password ? 'invalid' : ''
                }`}
              >
                <Input
                  type={passwordType}
                  placeholder="Confirme a senha"
                  value={passwordConfirmation}
                  invalid={!!validationErrors.passwordConfirmation}
                  onChange={({ target: { value } }) => setPasswordConfirmation(value)}
                />
                <InputGroupText onClick={() => setPasswordType((prevState) => (prevState === 'password' ? 'text' : 'password'))}>
                  <i
                    className={`fas ${passwordType === 'password'
                      ? 'fa-eye-slash'
                      : 'fa-eye'
                    }`}
                  />
                </InputGroupText>
                <FormFeedback>
                  {validationErrors.passwordConfirmation}
                </FormFeedback>
              </InputGroup>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSavePassword}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default Clientes;
