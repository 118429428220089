/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Fade, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, PopoverBody, PopoverHeader, Row, Table, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';

import classNames from 'classnames';
import { isDate, parseISO } from 'date-fns';

import { useManager } from '../../hooks/manager';
import { useDebounce } from '../../hooks/useDebounce';
import { numberToCurrency } from '../../utils/functions';
import DatePickerMaskedInput from '../DatePickerMaskedInput';
import Pagination from '../Pagination';

function TableManager({
  initialColumns = [],
  actions = [],
  onUpdate = () => null,
  onDelete = () => null,
  onCreatePdf,
  onCreateCsv,
  onSelectedRows,
  selectable = false,
  showTotalFinance = false,
  selectableColumns = false,
  enableDeletedItemsButton = false,
}) {
  const {
    currentUrl,
    load,
    update,
    datas = [],
    setDatas,
    hasNextPage,
    hasPrevPage,
    totalPages,
    totalDocs,
    extraData: {
      totalContaPagarFiltered = 0,
      totalContaReceberFiltered = 0,
    },
  } = useManager();

  // const [columns, setColumns] = useState(initialColumns);
  const [showSelectColumns, setShowSelectColums] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState(initialColumns);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState({});
  const [searchArray, setSearchArray] = useState([]);
  const [newValue, setNewValue] = useState({});
  const [newValueArray, setNewValueArray] = useState([]);
  const searchQuery = useDebounce(searchArray, 800);

  useEffect(() => {
    setSelectedRows([]);
    if (search?.field) {
      setSearchArray((prevState = []) => {
        const searchAdded = prevState.find((s) => (s.field === search.field));
        if (searchAdded) {
          return prevState.map((s) => (s.field === search.field ? search : s));
        }

        return [...prevState, search];
      });
    }
  }, [search]);

  useEffect(() => {
    if (newValue?.field) {
      setNewValueArray((prevState = []) => {
        const newValueAdded = prevState.find((s) => (s.field === newValue.field));
        if (newValueAdded) {
          return prevState.map((s) => (s.field === newValue.field ? newValue : s));
        }

        return [...prevState, newValue];
      });
    }
  }, [newValue]);

  const loadPageOrSearch = useCallback((pageNumber = 1, limitNumber = 10) => {
    // console.log('current base url', currentUrl, 'page number', pageNumber);
    setPage(pageNumber);
    setLimit(limitNumber);
    load(currentUrl, pageNumber, searchArray, limitNumber);
  }, [currentUrl, searchArray]);

  const clearFilter = useCallback((dataField) => {
    setSelectedRows([]);
    setPage(1);
    setSearchArray((prevSearchArray = []) => prevSearchArray.map((s) => (s.field === dataField ? { ...s, value: '' } : s)));
  }, [currentUrl, limit]);

  const clearRangeFilter = useCallback((dataField) => {
    setSelectedRows([]);
    setPage(1);
    setSearchArray((prevSearchArray = []) => prevSearchArray.map((s) => (s.field === `start${dataField}` ? { ...s, startRange: '' } : s.field === `end${dataField}` ? { ...s, endRange: '' } : s)));
  }, [currentUrl, limit]);

  const clearAllFilter = useCallback(() => {
    setSelectedRows([]);
    setPage(1);
    setSearchArray((prevSearchArray = []) => prevSearchArray.map((s) => ({ ...s, value: '', startRange: '', endRange: '' })));
  }, [currentUrl, limit]);

  const onSubmitSearch = useCallback(() => {
    loadPageOrSearch(1, limit);
  }, [limit, loadPageOrSearch]);

  useEffect(() => {
    if (searchQuery?.length) {
      onSubmitSearch();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (typeof onSelectedRows === 'function') {
      onSelectedRows(selectedRows);
    }
  }, [selectedRows]);

  const handleSelectAllRows = useCallback(() => {
    setSelectedRows((prevSelectedRows = []) => {
      if (prevSelectedRows.length === page * datas.length) {
        return [];
      }

      return [...prevSelectedRows.filter((selectedRow) => !datas.find((d) => d._id === selectedRow._id)), ...datas];
    });
  }, [datas, page]);

  const handleSelectColumn = useCallback((column) => {
    setSelectedColumns((prevSelectedColumns = []) => {
      if (prevSelectedColumns.find((selectedColumn) => selectedColumn.dataField === column.dataField)) {
        return prevSelectedColumns.filter((selectedColumn) => selectedColumn.dataField !== column.dataField);
      }

      return [...prevSelectedColumns.filter((selectedColumn) => selectedColumn.dataField !== column.dataField), column];
    });
  }, []);

  const handleSelectRow = useCallback((row) => {
    setSelectedRows((prevSelectedRows = []) => {
      if (prevSelectedRows.find((selectedRow) => selectedRow._id === row._id)) {
        return prevSelectedRows.filter((selectedRow) => selectedRow._id !== row._id);
      }

      return [...prevSelectedRows.filter((selectedRow) => selectedRow._id !== row._id), row];
    });
  }, []);

  const handleKeyDown = useCallback((event) => {
    const { keyCode } = event;
    // console.log(event);

    if (keyCode === 40) {
      // Down Key
      // currentItem += 1;
    } else if (keyCode === 38) {
      // Up Key
      /* if (currentItem > -1) {
        currentItem -= 1;
      } */
    } else if (keyCode === 13) {
      // Enter Key
      // event.preventDefault();
      // onSubmitSearch();
    }
  }, [onSubmitSearch]);

  const enableEditColumn = useCallback((row, column) => {
    setDatas((prevStates = []) => prevStates.map((r) => (r._id === row._id ? { ...r, enableEdit: true } : r)));
    setSelectedColumns((prevStates = []) => prevStates.map((c) => (c.dataField === column.dataField ? { ...c, enableEdit: true } : c)));
  }, []);

  const disableEditColumn = useCallback((row, column) => {
    setDatas((prevStates = []) => prevStates.map((r) => (r._id === row._id ? { ...r, enableEdit: false } : r)));
    setSelectedColumns((prevStates = []) => prevStates.map((c) => (c.dataField === column.dataField ? { ...c, enableEdit: false } : c)));
  }, []);

  const submitEdit = useCallback((row, column) => {
    setDatas((prevStates = []) => prevStates.map((r) => (r._id === row._id ? { ...r, enableEdit: false } : r)));
    setSelectedColumns((prevStates = []) => prevStates.map((c) => (c.dataField === column.dataField ? { ...c, enableEdit: false } : c)));
    const newV = newValueArray?.find((s) => s.field === `${row._id}-${column.dataField}`)?.value || '';
    if (typeof column.onConfirm === 'function') {
      column.onConfirm(row, newV);
      return;
    }
    update(row._id, currentUrl, { [column.dataField]: newV }, null, null, true, searchArray);
  }, [newValueArray, searchArray]);

  const handleEditKeyDown = useCallback((event, row, column) => {
    const { keyCode } = event;
    // console.log('KeyCode', keyCode);

    if (keyCode === 27) {
      // Escape key
      disableEditColumn(row, column);
    }

    if (keyCode === 13) {
      // Enter Key
      submitEdit(row, column);
    }
  }, [disableEditColumn, submitEdit]);

  const defaultActions = useMemo(() => [
    {
      name: 'view',
      tooltip: 'Alterar',
      color: 'info',
      className: 'mt-2 mr-2',
      icon: 'fas fa-edit',
      onClick: (row, _searchArray, _page, _limit) => onUpdate(row, _searchArray, _page, _limit),
    },
    {
      name: 'delete',
      tooltip: 'Inativar',
      color: 'danger',
      className: (row) => (row?.deletedAt ? 'd-none mt-2 mr-2' : 'mt-2 mr-2'),
      icon: 'fa-solid fa-ban',
      onClick: (row, _searchArray, _page, _limit) => onDelete(row, _searchArray, _page, _limit),
    },
    {
      name: 'reactive',
      tooltip: 'Reativar',
      color: 'success',
      className: (row) => (row?.deletedAt ? 'mt-2 mr-2' : 'd-none mt-2 mr-2'),
      icon: 'fa-solid fa-circle-check text-white',
      onClick: (row) => update(row._id, currentUrl, { deletedAt: null }, null, null, true, searchArray),
    },
  ], [update, onUpdate, onDelete, currentUrl, searchArray]);

  const newActions = useMemo(() => [
    ...actions,
    ...defaultActions,
  ], [defaultActions, actions]);

  const getRawValueColumn = useCallback((row, column) => {
    if (column.dataField.indexOf('.') > -1) {
      const columnSplit = column?.dataField?.split('.') || [];
      if (column.isArray) {
        return row[columnSplit?.[0]]?.[0]?.[columnSplit?.[1]];
      }

      return row[columnSplit?.[0]]?.[columnSplit?.[1]];
    }

    return row[column?.dataField];
  }, []);

  const renderValueColumn = useCallback((row, column) => {
    if (column.formatter) {
      return column.formatter(row);
    }

    return getRawValueColumn(row, column);
  }, []);

  const renderColumn = useCallback((row, column) => {
    if (typeof column.editable === 'function' && column.editable(row)) {
      return (
        <div className="d-flex align-items-center justify-content-between">
          {renderValueColumn(row, column)}
          <UncontrolledTooltip target={`column-editable-${row._id}-${column.dataField}`}>
            {`Editar ${column.text}`}
          </UncontrolledTooltip>
          <Button
            id={`column-editable-${row._id}-${column.dataField}`}
            color="link"
            onClick={() => enableEditColumn(row, column)}
          >
            <i className="fa-solid fa-pen" />
          </Button>
        </div>
      );
    }

    return renderValueColumn(row, column);
  }, []);

  const renderEditInputColumn = useCallback((row, column) => {
    console.log('🚀 ~ file: index.js:271 ~ renderEditInputColumn ~ row, column:', row, column);
    if (column.dataType === 'text') {
      return (
        <Input
          type="text"
          placeholder="Digite um valor"
          value={newValueArray.find(
            (s) => s.field === `${row._id}-${column.dataField}`,
          )
            ? newValueArray.find(
              (s) => s.field === `${row._id}-${column.dataField}`,
            ).value
            : getRawValueColumn(row, column)}
          onChange={({ target: { value } }) => {
            setSearch({
              field: `${row._id}-${column.dataField}`,
              value,
            });
          }}
          onKeyDown={(event) => handleEditKeyDown(event, row, column)}
        />
      );
    }

    if (column.dataType === 'money') {
      return (
        <NumericFormat
          customInput={Input}
          placeholder="Digite um valor"
          decimalSeparator=","
          thousandSeparator="."
          decimalScale={2}
          prefix="R$"
          value={newValueArray.find(
            (s) => s.field === `${row._id}-${column.dataField}`,
          )
            ? newValueArray.find(
              (s) => s.field === `${row._id}-${column.dataField}`,
            ).value
            : getRawValueColumn(row, column)}
          fixedDecimalScale
          valueIsNumericString
          onValueChange={({ floatValue }) => setNewValue({
            field: `${row._id}-${column.dataField}`,
            value: floatValue,
          })}
          onKeyDown={(event) => handleEditKeyDown(event, row, column)}
        />
      );
    }

    if (column.dataType === 'date') {
      return (
        <DatePickerMaskedInput
          placeholderText={column.placeholder || 'Data de'}
          selected={newValueArray.find(
            (s) => s.field === `${row._id}-${column.dataField}`,
          )
            ? newValueArray.find(
              (s) => s.field === `${row._id}-${column.dataField}`,
            ).value
            : isDate(getRawValueColumn(row, column)) ? parseISO(getRawValueColumn(row, column)) : ''}
          onChange={(date) => {
            console.log(`onChange date ${date}`);
            if (date) {
              setNewValue({
                field: `${row._id}-${column.dataField}`,
                value: date,
              });
            }
          }}
          onKeyDown={(event) => handleEditKeyDown(event, row, column)}
          withFormGroup={false}
        />
      );
    }

    return renderValueColumn(row, column);
  }, [newValueArray]);

  const renderActions = (row, _searchArray, _page, _limit) => newActions.map((a) => (
    <div key={`${a.name}-${row._id}`}>
      <UncontrolledTooltip target={`button-${a.name}-${row._id}`}>
        {typeof a.tooltip === 'function' ? a.tooltip(row) : a.tooltip}
      </UncontrolledTooltip>
      <Button
        id={`button-${a.name}-${row._id}`}
        color={typeof a.color === 'function' ? a.color(row) : a.color}
        size="sm"
        className={typeof a.className === 'function' ? a.className(row) : a.className}
        onClick={() => a.onClick(row, _searchArray, _page, _limit)}
      >
        {typeof a.icon === 'function' ? a.icon(row) : <i className={a.icon} />}
      </Button>
    </div>
  ));

  return (
    <>
      <Card>
        <CardBody>
          <Row style={{ height: '3rem' }}>
            <Col>
              <div className="d-flex justify-content-between pb-2">
                <div className="d-flex">
                  {selectableColumns && (
                    <Button
                      color="primary"
                      className="mr-2 mb-2 animate__animated animate__fadeInLeft"
                      onClick={() => setShowSelectColums(true)}
                    >
                      <i className="fa-solid fa-list-check mr-2" />
                      Selecionar Colunas
                    </Button>
                  )}
                  <Button
                    color="info"
                    className={classNames('mr-2 mb-2', {
                      'visibility-visible animate__animated animate__fadeInLeft': typeof onUpdate === 'function' && selectedRows.length === 1,
                      'visibility-hidden animate__animated animate__fadeOutLeft': typeof onUpdate === 'function' && selectedRows.length !== 1,
                    })}
                    onClick={() => onUpdate(selectedRows[0], searchArray, page, limit)}
                  >
                    <i className="fa-solid fa-pen-to-square mr-2" />
                    Editar
                  </Button>
                  {/* <Button
                    color="danger"
                    className={classNames('mr-2 mb-2', {
                      'visibility-visible': selectedRows.length,
                      'visibility-hidden': !selectedRows.length,
                      'animate__animated animate__slideOutLeft': typeof onUpdate === 'function' && selectedRows.length !== 1,
                    })}
                  >
                    <i className="fa-solid fa-trash mr-2" />
                    {`Inativar ${selectedRows.length} selecionado(s)`}
                  </Button> */}
                </div>
                <div className="d-flex align-items-center">
                  {datas.length && selectedRows?.length && onCreatePdf ? (
                    <Fade>
                      <UncontrolledTooltip target="#create-pdf-button">Gerar Arquivo PDF</UncontrolledTooltip>
                      <Button id="create-pdf-button" color="link" onClick={() => onCreatePdf(selectedRows)}>
                        <i className="fa-solid fa-file-pdf text-danger" style={{ fontSize: '2rem' }} />
                      </Button>
                    </Fade>
                  ) : null}
                  {datas.length && selectedRows?.length && onCreateCsv ? (
                    <Fade>
                      <UncontrolledTooltip target="#create-csv-button">Gerar Arquivo CSV</UncontrolledTooltip>
                      <Button id="create-csv-button" color="link" onClick={() => onCreateCsv(selectedRows)}>
                        <i className="fa-solid fa-file-csv text-success" style={{ fontSize: '2rem' }} />
                      </Button>
                    </Fade>
                  ) : null}
                  {enableDeletedItemsButton ? (
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={searchArray?.find((s) => s.field === 'deletedAt')?.value || false}
                        onClick={() => {
                          setSearch({
                            field: 'deletedAt',
                            value: !searchArray?.find((s) => s.field === 'deletedAt')?.value || false,
                          });
                        }}
                      />
                      <Label check>Exibir registro(s) inativo(s)</Label>
                    </FormGroup>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          {showTotalFinance && (
            <Row>
              <Col xs={12} sm={9} lg={6}>
                <Card>
                  <CardHeader tag="div" className="d-flex justify-content-between">
                    <span>Resultado do filtro</span>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={searchArray?.find((s) => s.field === 'canceled')?.value || false}
                        onClick={() => {
                          setSearch({
                            field: 'canceled',
                            value: !searchArray?.find((s) => s.field === 'canceled')?.value,
                          });
                        }}
                      />
                      <Label check>Não considerar cancelados</Label>
                    </FormGroup>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs={12} sm={4}>
                        <Row>
                          <Col>
                            <strong>Total a Pagar</strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {numberToCurrency(totalContaPagarFiltered)}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} sm={4}>
                        <Row>
                          <Col>
                            <strong>Total a Receber</strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {numberToCurrency(totalContaReceberFiltered)}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} sm={4}>
                        <Row>
                          <Col>
                            <strong>Saldo</strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <span className={classNames({ 'text-success': (totalContaReceberFiltered - totalContaPagarFiltered >= 0), 'text-danger': (totalContaReceberFiltered - totalContaPagarFiltered < 0) })}>{numberToCurrency(totalContaReceberFiltered - totalContaPagarFiltered)}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) }
          <Table striped responsive>
            <thead>
              <tr>
                {selectable && (
                  <th rowSpan={2} className="text-center align-middle" style={{ width: '50px', minWidth: '50px' }}>
                    <Input
                      type="checkbox"
                      className="ml-0 position-relative"
                      checked={datas.length && selectedRows.length >= page * datas.length}
                      onChange={handleSelectAllRows}
                    />
                  </th>
                )}
                {selectedColumns.map((column) => (
                  <th
                    key={column.id || column.dataField}
                    style={{
                      verticalAlign: 'top',
                      width: column.width || '150px',
                      minWidth: column.width || '150px',
                    }}
                  >
                    <UncontrolledTooltip target={`#column-${column.id || column.dataField}`} fade>
                      {column.text}
                    </UncontrolledTooltip>
                    <div id={`column-${column.id || column.dataField}`} className="p-1 text-truncate">
                      {column.text}
                      {column.textFilter ? (
                        <Row>
                          <Col>
                            <div className="d-flex">
                              <FormGroup className="w-100">
                                <Input
                                  type="text"
                                  bsSize="sm"
                                  placeholder={column.placeholder || `Buscar por ${column.text}`}
                                  value={
                                    searchArray.find(
                                      (s) => s.field === column.dataField,
                                    )
                                      ? searchArray.find(
                                        (s) => s.field === column.dataField,
                                      ).value
                                      : ''
                                  }
                                  onChange={({ target: { value } }) => {
                                    setSearch({
                                      field: column.dataField,
                                      value,
                                    });
                                  }}
                                  onKeyDown={handleKeyDown}
                                />
                              </FormGroup>
                              {searchArray.find(
                                (s) => s.field === column.dataField && s.value !== '',
                              ) ? (
                                <Button
                                  className="ml-1"
                                  color="danger"
                                  size="sm"
                                  style={{
                                    height: '2rem',
                                  }}
                                  onClick={() => clearFilter(column.dataField)}
                                >
                                  <i className="fa-solid fa-filter-circle-xmark" />
                                </Button>
                                ) : null}
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {column.selectFilter ? (
                        <Row>
                          <Col>
                            <div className="d-flex">
                              <FormGroup className="w-100">
                                <Input
                                  type="select"
                                  bsSize="sm"
                                  value={
                                    searchArray.find(
                                      (s) => s.field === column.dataField,
                                    )
                                      ? searchArray.find(
                                        (s) => s.field === column.dataField,
                                      ).value
                                      : ''
                                  }
                                  onChange={({ target: { value } }) => {
                                    setSearch({
                                      field: column.dataField,
                                      value,
                                    });
                                  }}
                                >
                                  <option value="">Selecione</option>
                                  {column.selectFilter.map((s) => (
                                    <option key={s.value} value={s.value}>
                                      {s.label}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                              {searchArray.find(
                                (s) => s.field === column.dataField && s.value !== '',
                              ) ? (
                                <Button
                                  className="ml-1"
                                  color="danger"
                                  size="sm"
                                  style={{
                                    height: '2rem',
                                  }}
                                  onClick={() => clearFilter(column.dataField)}
                                >
                                  <i className="fa-solid fa-filter-circle-xmark" />
                                </Button>
                                ) : null}
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {column.dateFilter ? (
                        <Row>
                          <Col>
                            <div className="d-flex">
                              <Button
                                id={`${column.dataField}-uncontrolledPopoverDateFilter`}
                                color="info"
                                size="sm"
                                block
                              >
                                Filtrar
                              </Button>
                              {searchArray.find(
                                (s) => (s.field === `start${column.dataField}` && s.startRange !== '') || (s.field === `end${column.dataField}` && s.endRange !== ''),
                              ) ? (
                                <Button
                                  className="ml-1"
                                  color="danger"
                                  size="sm"
                                  style={{
                                    height: '2rem',
                                  }}
                                  onClick={() => clearRangeFilter(column.dataField)}
                                >
                                  <i className="fa-solid fa-filter-circle-xmark" />
                                </Button>
                                ) : null}
                              <UncontrolledPopover
                                placement="bottom"
                                target={`${column.dataField}-uncontrolledPopoverDateFilter`}
                                trigger="legacy"
                              >
                                <PopoverHeader>
                                  {`Filtro por ${column.text}`}
                                </PopoverHeader>
                                <PopoverBody>
                                  <Row>
                                    <Col>
                                      <DatePickerMaskedInput
                                        placeholderText={column.placeholder || 'Data de'}
                                        inputSize="sm"
                                        selected={
                                          searchArray.find(
                                            (s) => s.field === `start${column.dataField}`,
                                          )
                                            ? searchArray.find(
                                              (s) => s.field === `start${column.dataField}`,
                                            ).startRange || ''
                                            : ''
                                        }
                                        onChange={(date) => {
                                          // console.log(`onChange date ${date}`);
                                          if (date) {
                                            setSearch({
                                              field: `start${column.dataField}`,
                                              startRange: date,
                                            });
                                          }
                                        }}
                                      />
                                    </Col>
                                    <Col>
                                      <DatePickerMaskedInput
                                        placeholderText={column.placeholder || 'Data Até'}
                                        inputSize="sm"
                                        selected={
                                          searchArray.find(
                                            (s) => s.field === `end${column.dataField}`,
                                          )
                                            ? searchArray.find(
                                              (s) => s.field === `end${column.dataField}`,
                                            )?.endRange || ''
                                            : ''
                                        }
                                        onChange={(date) => {
                                          // console.log(`onChange date ${date}`);
                                          if (date) {
                                            setSearch({
                                              field: `end${column.dataField}`,
                                              endRange: date,
                                            });
                                          }
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </PopoverBody>
                              </UncontrolledPopover>
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {column.moneyRangeFilter ? (
                        <Row>
                          <Col>
                            <div className="d-flex">
                              <Button
                                id={`${column.dataField}-uncontrolledPopoverMoneyRangeFilter`}
                                color="info"
                                size="sm"
                                block
                              >
                                Filtrar
                              </Button>
                              {searchArray.find(
                                (s) => (s.field === `start${column.dataField}` && s.startRange !== '') || (s.field === `end${column.dataField}` && s.endRange !== ''),
                              ) ? (
                                <Button
                                  className="ml-1"
                                  color="danger"
                                  size="sm"
                                  style={{
                                    height: '2rem',
                                  }}
                                  onClick={() => clearRangeFilter(column.dataField)}
                                >
                                  <i className="fa-solid fa-filter-circle-xmark" />
                                </Button>
                                ) : null}
                              <UncontrolledPopover
                                placement="bottom"
                                target={`${column.dataField}-uncontrolledPopoverMoneyRangeFilter`}
                                trigger="legacy"
                              >
                                <PopoverHeader>
                                  {`Filtro por ${column.text}`}
                                </PopoverHeader>
                                <PopoverBody>
                                  <Row>
                                    <Col>
                                      <NumericFormat
                                        customInput={Input}
                                        placeholder="Digite um valor"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        decimalScale={2}
                                        prefix="R$"
                                        value={searchArray.find(
                                          (s) => s.field === `start${column.dataField}`,
                                        )
                                          ? searchArray.find(
                                            (s) => s.field === `start${column.dataField}`,
                                          )?.startRange || ''
                                          : ''}
                                        fixedDecimalScale
                                        valueIsNumericString
                                        onValueChange={({ floatValue }) => setSearch({
                                          field: `start${column.dataField}`,
                                          startRange: floatValue,
                                        })}
                                      />
                                    </Col>
                                    <Col>
                                      <NumericFormat
                                        customInput={Input}
                                        placeholder="Digite um valor"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        decimalScale={2}
                                        prefix="R$"
                                        value={searchArray.find(
                                          (s) => s.field === `end${column.dataField}`,
                                        )
                                          ? searchArray.find(
                                            (s) => s.field === `end${column.dataField}`,
                                          )?.endRange || ''
                                          : ''}
                                        fixedDecimalScale
                                        valueIsNumericString
                                        onValueChange={({ floatValue }) => setSearch({
                                          field: `end${column.dataField}`,
                                          endRange: floatValue,
                                        })}
                                      />
                                    </Col>
                                  </Row>
                                </PopoverBody>
                              </UncontrolledPopover>
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                    </div>
                  </th>
                ))}
                <th style={{ verticalAlign: 'top', width: '200px', minWidth: '200px' }}>
                  <div className="d-flex flex-column justify-content-between align-items-end h-100">
                    <div>#</div>
                    <div>
                      {searchArray.filter((s) => s.value).length > 1 ? (
                        <Button
                          className="ml-1"
                          color="danger"
                          size="sm"
                          style={{
                            height: '2rem',
                          }}
                          onClick={clearAllFilter}
                        >
                          <i className="fa-solid fa-filter-circle-xmark mr-2" />
                          Limpar Filtros
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {datas.map((row) => (
                <tr key={row._id}>
                  {selectable && (
                    <td valign="middle" align="center" style={{ width: '50px', minWidth: '50px' }}>
                      <Input
                        type="checkbox"
                        className="ml-0 position-relative"
                        checked={!!selectedRows.filter((selectedRow) => selectedRow._id === row._id).length}
                        onChange={() => handleSelectRow(row)}
                      />
                    </td>
                  )}
                  {selectedColumns.map((column) => (
                    <td
                      key={`${row._id}-${column.dataField}`}
                      className={column.className}
                    >
                      <Fade className="text-truncate">
                        {typeof column.onClick === 'function' ? <Button color="link" className="text-decoration-none text-black" onClick={() => column.onClick(row, searchArray, page, limit)} block>{renderValueColumn(row, column)}</Button> : row.enableEdit && column.enableEdit ? renderEditInputColumn(row, column) : renderColumn(row, column)}
                      </Fade>
                    </td>
                  ))}
                  <td>
                    <Fade>
                      <div className="d-flex flex-wrap justify-content-end">
                        {renderActions(row, searchArray, page, limit)}
                      </div>
                    </Fade>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
        {datas.length ? (
          <Fade>
            <CardFooter>
              <Row>
                <Col xs={12} sm={4} md={2}>
                  <FormGroup>
                    <Input
                      id="limit"
                      name="limit"
                      type="select"
                      value={limit}
                      onChange={({ target: { value } }) => loadPageOrSearch(1, value)}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      { totalDocs > 50 && <option value={totalDocs}>Tudo</option> }
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={8} md={10}>
                  <Row>
                    <Col xs={12} tag="div" className="d-flex flex-wrap justify-content-center align-items-center justify-content-sm-end h-100">
                      {selectable ? (
                        <div className="text-right mr-2 mb-2">
                          {`Selecionado(s) ${selectedRows.length} de ${page * limit > totalDocs ? totalDocs : page * limit}/${totalDocs}`}
                        </div>
                      ) : (
                        <div className="text-right mr-2 mb-2">
                          {`Total de documento(s) ${page * limit > totalDocs ? totalDocs : page * limit}/${totalDocs}`}
                        </div>
                      )}
                      <Pagination
                        page={page}
                        hasNextPage={hasNextPage}
                        hasPrevPage={hasPrevPage}
                        totalPages={totalPages}
                        onPageChange={(value) => loadPageOrSearch(value, limit)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardFooter>
          </Fade>
        ) : (
          <Fade>
            <CardFooter>
              <div className="d-flex justify-content-center">
                <small>Nenhum resultado encontrado</small>
              </div>
            </CardFooter>
          </Fade>
        )}
      </Card>
      <Modal
        isOpen={showSelectColumns}
        onClose={() => setShowSelectColums(false)}
        centered
      >
        <ModalHeader>
          <strong>Seleciona as colunas que deseja exibir na tabela</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowSelectColums(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          {initialColumns.map((column) => (
            <FormGroup key={`selectable-column-${column.id || column.dataField}`} check>
              <Input
                type="checkbox"
                checked={!!selectedColumns.find((selectedColumn) => selectedColumn.dataField === column.dataField)}
                onChange={() => handleSelectColumn(column)}
              />
              <Label check>{column.text}</Label>
            </FormGroup>
          ))}
        </ModalBody>
        <ModalFooter tag="div" className="d-flex justify-content-between">
          <div>
            {selectedColumns.length !== initialColumns.length && (
              <Button color="primary" className="mr-2" onClick={() => setSelectedColumns(initialColumns)}>
                Restaurar
              </Button>
            )}
            <Button color="warning" onClick={() => setSelectedColumns([])}>
              Limpar
            </Button>
          </div>
          <div>
            <Button color="danger" onClick={() => setShowSelectColums(false)}>
              Fechar
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default TableManager;
