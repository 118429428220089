/* eslint-disable max-len */
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import { yupResolver } from '@hookform/resolvers/yup';

import CustomSelect from '../../components/CustomSelect';
import DatePickerMaskedInput from '../../components/DatePickerMaskedInput';
import MoneyMaskedInput from '../../components/MoneyMaskedInput';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';
import { schema } from './validationSchema';

const defaultValues = {
  contaPagarPara: 'fornecedor',
};

function ContaPagar() {
  const navigate = useNavigate();
  const { loading, setLoading, notificationRef } = useContext(AppContext);
  const [profissionais, setProfissionais] = useState([]);
  const [fornecedores, setFornecedores] = useState([]);
  const { control, watch, setError, handleSubmit, formState: { errors } } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const watchContaPagarPara = watch('contaPagarPara');

  useEffect(() => {
    const loadProfissionais = async () => {
      const { data = [] } = await api.get('/profissionais?limit=0');
      return data;
    };

    const loadFornecedores = async () => {
      const { data = [] } = await api.get('/fornecedores?limit=0');
      return data;
    };

    (async () => {
      try {
        const [profissionaisData = [], fornecedoresData = []] = await Promise.all([loadProfissionais(), loadFornecedores()]);
        // console.log(profissionaisData, fornecedoresData);
        setProfissionais(profissionaisData.map((d) => ({
          value: d._id,
          label: d.name,
        })));

        setFornecedores(fornecedoresData.map((d) => ({
          value: d._id,
          label: d.nome,
        })));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const onSubmit = useCallback((formValues) => {
    const { contaPagarPara, fornecedor, profissional, valor, dataVencimento } = formValues;
    // console.log(formValues);

    (async () => {
      try {
        if (contaPagarPara === 'fornecedor' && !fornecedor?.value) {
          setError('fornecedor', { type: 'manual', message: 'Selecione um fornecedor' });
          return;
        }

        if (contaPagarPara === 'profissional' && !profissional?.value) {
          setError('profissional', { type: 'manual', message: 'Selecione um profissional' });
        }

        setLoading(true);
        const data = {
          tipo: 'conta-pagar',
          valor,
          dataVencimento,
        };

        if (contaPagarPara === 'fornecedor') {
          Object.assign(data, {
            fornecedor: fornecedor?.value,
          });
        } else {
          Object.assign(data, {
            profissional: profissional?.value,
          });
        }

        console.log(data);
        await api.post('/financeiros', data);
        setLoading(false);
        notificationRef.current.notify({
          message: 'Conta a pagar criada com sucesso',
        });

        setTimeout(() => {
          navigate('/financeiro');
        }, 1000);
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao cadastrar fatura',
          color: 'danger',
        });
      }
    })();
  }, [setError]);

  return (
    <Container fluid>
      <Row className="mt-5">
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Cadastrar Conta a Pagar</h3>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row className="mb-4">
                  <Col>
                    <Controller
                      name="contaPagarPara"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormGroup check>
                          <Input
                            type="radio"
                            value="fornecedor"
                            checked={value === 'fornecedor'}
                            onChange={onChange}
                          />
                          <Label check>Fornecedor</Label>
                        </FormGroup>
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col>
                    <Controller
                      name="contaPagarPara"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormGroup check>
                          <Input
                            type="radio"
                            value="profissional"
                            checked={value === 'profissional'}
                            onChange={onChange}
                          />
                          <Label check>Profissional</Label>
                        </FormGroup>
                      )}
                    />
                  </Col>
                </Row>
                {watchContaPagarPara === 'fornecedor' ? (
                  <Row>
                    <Col>
                      <Controller
                        name="fornecedor"
                        control={control}
                        render={({ field }) => (
                          <CustomSelect
                            label="Fornecedor"
                            placeholder="Selecione um Fornecedor"
                            invalidMessage={errors?.fornecedor?.message}
                            {...field}
                            options={fornecedores}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col>
                      <Controller
                        name="profissional"
                        control={control}
                        render={({ field }) => (
                          <CustomSelect
                            label="Profissional"
                            placeholder="Selecione um Profissional"
                            invalidMessage={errors?.profissional?.message}
                            {...field}
                            options={profissionais}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Controller
                      name="valor"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <MoneyMaskedInput
                          label="Valor"
                          name={name}
                          invalidMessage={errors?.valor?.message}
                          value={value}
                          onValueChange={({ floatValue }) => onChange(floatValue)}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Controller
                      name="dataVencimento"
                      control={control}
                      render={({ field }) => (
                        <DatePickerMaskedInput
                          label="Data do Vencimento"
                          invalidMessage={errors?.dataVencimento?.message}
                          onChange={(e) => field.onChange(e)}
                          selected={field.value}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12} sm={6}>
            <Button type="submit" color="success" disabled={loading} block>Cadastrar</Button>
          </Col>
          <Col xs={12} sm={6}>
            <Button color="danger" disabled={loading} onClick={() => navigate(-1)} block>Cancelar</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default ContaPagar;
